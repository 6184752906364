<script setup>
import axios from 'axios';
import { computed, ref, watch } from 'vue';

import { pluralize } from '@/utils';
import { useToast } from '@/plugins/toast';

import IconCameraAdd from '@/components/Icons/CameraAdd.vue';
import IconCancel from '@/components/Icons/Cancel.vue';

const model = defineModel();
const toast = useToast();
const fileInput = ref();

const imageTypes = 'image/jpeg,image/png,image/webp,image/heic';
const videoTypes = 'video/mp4,video/avi,video/mpeg,video/quicktime,video/webm';
const typeMap = {
    image: 1,
    video: 2,
}

const props = defineProps({
    roundedFull: {
        required: false,
        default: false,
    },
    acceptImage: {
        required: false,
        type: Boolean,
        default: true,
    },
    acceptVideo: {
        required: false,
        type: Boolean,
        default: false,
    },
    size: {
        required: false,
        type: String,
    },
    multiple: {
        required: false,
        type: Boolean,
        default: false,
    },
    max: {
        required: false,
        type: Number,
        default: 8,
    },
    isPrivate: {
        required: false,
        default: false,
        type: Boolean,
    },
    as: {
        required: false,
        default: 'button',
    }
});

const newFiles = ref([]);

const getAccept = computed(() => {
    if (!props.acceptImage && !props.acceptVideo) {
        return imageTypes + ',' + videoTypes;
    }

    let types = [];

    if (props.acceptImage) {
        types.push(imageTypes);
    }
    if (props.acceptVideo) {
        types.push(videoTypes);
    }

    return types.join(',');
});

const limitExceeded = computed(() => {
    if (!props.multiple) {
        return false;
    }

    return (model.value.length + newFiles.value.length) >= props.max;
});

const openPicker = () => {
    if (limitExceeded.value) {
        const filesw = pluralize('file', props.max);
        toast.error(`Only a max of ${props.max} ${filesw} can be uploaded.`);
        return;
    }

    fileInput.value.click()
}

const getNextOrder = () => {
    if (!props.multiple) {
        return 0;
    }

    const lastNewFile = newFiles.value[newFiles.value.length - 1];

    if (lastNewFile) {
        return lastNewFile.order + 1;
    }

    const lastFile = props.multiple ? model.value[model.value.length - 1] : model.value;

    return lastFile ? lastFile.order + 1 : 0;
}

const fileChanged = (e) => {
    for (const value of e.target.files) {
        if (limitExceeded.value) {
            const filesw = pluralize('file', props.max);
            toast.error(`Only a max of ${props.max} ${filesw} can be uploaded.`);
            return;
        }

        const newFile = {
            id: Math.random().toString(36).slice(2, 11),
            url: URL.createObjectURL(value),
            type: typeMap[value.type.split('/')[0]],
            order: getNextOrder(),
            status: 1,
        };

        newFiles.value.push(newFile);

        axios.post('/api/files', {
            file: value,
            is_private: props.isPrivate ? 1 : 0,
            order: newFile.order,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            const data = res.data.data;

            if (props.multiple) {
                model.value.push(data);
                const index = newFiles.value.findIndex(item => item.id === newFile.id);
                newFiles.value.splice(index, 1);

            } else {
                model.value = data;
            }
        }).catch(error => {
            const index = newFiles.value.findIndex(item => item.id === newFile.id);
            const file = newFiles.value[index];

            if (file) {
                file.status = 3;
            }

            const errPrefix = value.name + ': ';

            if (!error.response) {
                toast.error(errPrefix + 'An error occured');
                return;
            }

            if (error.response.status === 422 && error.response.data?.errors?.file) {
                toast.error(errPrefix + error.response.data.errors.file[0]);
            } else if (error.response.status === 400) {
                toast.error(errPrefix + error.response.data.message || 'Invalid request.');
            } else {
                toast.error(errPrefix + 'An error occured');
            }
        });
    };

    e.target.value = null;
}

const removeFile = (file) => {
    if (!props.multiple) {
        model.value = null;
        newFiles.value = [];
        return;
    }

    const modelIndex = model.value.findIndex(item => item.id === file.id);
    if (modelIndex !== -1) {
        model.value.splice(modelIndex, 1);
    }

    const newFilesIndex = newFiles.value.findIndex(item => item.id === file.id);
    if (newFilesIndex !== -1) {
        newFiles.value.splice(newFilesIndex, 1);
    }
}

defineExpose({
    openPicker,
    newFiles,
    model,
    removeFile,
});
</script>

<template>
    <component :is="as" @click="openPicker">
        <slot></slot>
        <input :max="max" aria-hidden="true" v-on:change="fileChanged" hidden :multiple="multiple" ref="fileInput" type="file" :accept="getAccept">
    </component>
</template>
