import { reactive, inject } from "vue";

export function useStore() {
    const store = inject('store');

    if (!store) {
        throw new Error('useStore() is called without provider.');
    }

    return store;
}

export function createStore() {
    const state = reactive({
        user: null,
        deviceType: 'mobile',
        loading: false,
        newWorker: null,
        feedPromptOpened: false,
    });

    return {
        state,
        commit(prop, value) {
            state[prop] = value;
        },
        saveUser() {
            localStorage.setItem('me', JSON.stringify(state.user));
        }
    }
}
