import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import weekday from 'dayjs/plugin/weekday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPlatoon() {
    const platoon = localStorage.getItem('platoon');

    if (platoon && platoon >= 1 && platoon <= 20) {
        return platoon;
    }

    return null;
}

export function getDeviceType() {
    if (typeof window === 'undefined') {
        return 'desktop';
    }

    if (window.matchMedia('(max-width: 767.9px)').matches) {
        return 'mobile';
    }

    if (window.matchMedia('(min-width: 768px) and (max-width: 1023.9px)').matches) {
        return 'tablet';
    }

    return 'desktop';
}

export const getFriendlyTime = (date, { withTime = true } = {}) => {
    const dayjsDate = dayjs(date);
    const now = dayjs();

    const time = withTime ? ' ' + dayjsDate.format('h:mm A') : '';

    if (dayjsDate.isToday()) {
        return dayjsDate.format('h:mm A');
    } else if (dayjsDate.isYesterday()) {
        return 'Yesterday' + time;
    } else if (dayjsDate.isSame(now, 'week')) {
        return dayjsDate.format('ddd') + time;
    } else if (dayjsDate.isSame(now, 'year')) {
        return dayjsDate.format('MMMM D') + time;
    } else {
        return dayjsDate.format('MMMM D, YYYY') + time;
    }
}

export const getEventMessageContent = (message, conversation) => {
    const you = message.member.id == conversation.id;

    if (message.content.type == 'conversation.accepted') {
        return you ? 'You accepted the request to chat' : `Your request to chat accepted`;
    }

    console.error('Unknown event message type', message.content.type);

    return '';
}

export const debounce = (fn, delay) => {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => fn(...args), delay);
    }
}

export const scrollElementIntoView = ({ el, top = true, extra = 0 }) => {
    const targetPosition = (top ? el.getBoundingClientRect().top : el.getBoundingClientRect().bottom) + window.scrollY;
    const scrollPosition = top ? targetPosition + extra : targetPosition - window.innerHeight + extra;

    window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
    });
}

export const scrollElementIntoViewIfNeeded = ({ el, top = true, extra = 0 }) => {
    const isInViewport = (elem) => {
        const rect = elem.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    if (!isInViewport(el)) {
        scrollElementIntoView({
            el, top, extra
        });
    }
}

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(deepCopy);
    }

    const copy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }
    return copy;
}

export const pluralize = (word, count, pluralWord = null) => {
    if (count == 1) {
        return word;
    }

    return pluralWord ? pluralWord : word + 's';
}

export const shouldShowItem = (item) => {
    if ('hide' in item) {
        return typeof item.hide === 'function' ? !item.hide() : Boolean(!item.hide);
    }

    if ('show' in item) {
        return typeof item.show === 'function' ? item.show() : Boolean(item.show);
    }

    return true;
}

export const abbreviateNumber = (number) => {
    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1
    }).format(number);
}

export const formatNumberWithCommas = (number) => {
    return Intl.NumberFormat('en-US').format(number);
}
