<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import ScrollArea from '@/components/ScrollArea/ScrollArea.vue';

import EditMedia from '@/views/modals/Media/EditMedia.vue';
import IconCancelBold from '@/components/Icons/CancelBold.vue';
import IconError from '@/components/Icons/Error.vue';
import IconLoader from '@/components/Icons/Loader.vue';

const props = defineProps({
    picker: {
        required: false,
        type: Object,
    }
});

const showImage = ref(false);
const router = useRouter();
const route = useRoute();

const files = computed(() => {
    if (!props.picker) {
        return [];
    }

    const modelFiles = Array.isArray(props.picker.model) ? props.picker.model : [props.picker.model];

    const pendingOrFailedFiles = props.picker.newFiles.filter(f => f.status != 2);

    return [...modelFiles, ...pendingOrFailedFiles].sort((a, b) => a.order - b.order);
});

const fileToUpdate = ref(null);

const fileUpdated = () => {
    fileToUpdate.value = null;
}

const openEditModal = (file) => {
    fileToUpdate.value = file;
    router.push({ query: { ...route.query, editMedia: null } });
}

onMounted(() => {
    setTimeout(() => {
        showImage.value = true;
    }, 100);
});
</script>

<template>
    <ScrollArea>
        <ul v-if="files.length > 0" class="flex flex-row gap-4 w-full overflow-hidden pr-4">
            <li v-for="file, i in files" :key="i">
                <div class="relative h-[306px] flex items-end">
                    <div class="relative h-[290px] w-[250px] rounded-md cursor-pointer" @click.stop="file.status === 2 ? openEditModal(file) : null">
                        <div class="absolute w-full h-full left-0 top-0 rounded-[inherit]">
                        </div>
                        <div v-if="file.status == 1 || file.status == 3" class="w-full h-full absolute top-0 left-0 flex items-center justify-center">
                            <IconError v-if="file.status == 3" class="size-6 text-[#ffffff]" />
                            <IconLoader v-else-if="file.status == 1" class="size-6 text-[#ffffff]" />
                        </div>
                        <template v-if="showImage">
                            <img
                                v-if="file.type == 1"
                                class="h-full w-full object-cover rounded-[inherit]"
                                :src="(file.thumbnails && file.thumbnails.find((t) => t.height == 900)) ? file.thumbnails.find(t=>t.height == 900).url : file.url"
                                loading="lazy"
                            />
                            <video v-else-if="file.type == 2">
                                <source :src="file.url">
                            </video>
                        </template>
                    </div>
                    <div v-if="file.status !=1" class="absolute top-6 flex justify-between px-2 w-full">
                        <button
                            v-if="file.status === 2"
                            @click="openEditModal(file)"
                            type="button"
                            class="flex items-center justify-center bg-black/80 text-black-foreground rounded-full px-4"
                        >
                            Edit
                        </button>
                        <button
                            @click="picker.removeFile(file)"
                            aria-label="remove file"
                            type="button"
                            class="flex items-center justify-center bg-black/80 text-black-foreground size-7 rounded-full"
                        >
                            <IconCancelBold class="size-5" />
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <EditMedia :file="fileToUpdate" />
    </ScrollArea>
</template>
