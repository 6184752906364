<script setup>
import Cropper from 'cropperjs';

import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { DialogClose } from 'radix-vue';

import axios from '@/axios';

import AppButton from '@/components/Button/Button.vue';
import Base from '@/views/modals/_partials/Base.vue';
import Body from '@/views/modals/_partials/Body.vue';
import Header from '@/views/modals/_partials/Header.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import IconCancelX from '@/components/Icons/Back.vue';
import IconCheckBold from '@/components/Icons/CheckBold.vue';
import IconClear from '@/components/Icons/Clear.vue';
import IconCrop from '@/components/Icons/Crop.vue';
import IconRotateClockwise from '@/components/Icons/RotateClockwise.vue';
import IconZoomIn from '@/components/Icons/ZoomIn.vue';
import IconZoomOut from '@/components/Icons/ZoomOut.vue';

const route = useRoute();
const router = useRouter();

const props = defineProps({
    file: {
        type: Object,
    },
});

const open = computed(() => Boolean('editMedia' in route.query && props.file));

const close = () => {
    router.back();
};

const image = ref(null);

const imageEditor = reactive({
    cropper: null,
    action: null,
    cropActive: false,
    isClean: true,
    initialData: null,
    crop() {
        if (this.action === 'crop' || this.cropActive) {
            this.cropper?.clear();
            this.action = null;
            this.cropActive = false;
        } else {
            this.cropper?.crop();
            this.action = 'crop';
            this.cropActive = true;
        }
    },
    rotate() {
        this.cropper?.rotate(90);
        this.action = 'rotate';
        this.isClean = imageEditorIsClean();
    },
    zoomIn() {
        this.cropper?.zoom(0.1);
        this.action = 'zoomIn';
    },
    zoomOut() {
        this.cropper?.zoom(-0.1);
        this.action = 'zoomOut';
    },
    reset() {
        this.cropper?.clear();
        this.cropper?.reset();
        this.action = null;
        this.cropActive = false;
    },
});

const imageEditorIsClean = () => {
    const data = imageEditor?.cropper?.getData();
    const initialData = imageEditor.initialData;

    if (!data || !initialData) {
        return true;
    }

    return Object.keys(data).every(key => data[key] === initialData[key]);
};

const initEditor = () => {
    imageEditor.cropActive = false;
    imageEditor.action = null;
    imageEditor.isClean = true;
    imageEditor.cropper = new Cropper(image.value, {
        viewMode: 3,
        autoCrop: false,
        autoCropArea: 1,
        dragMode: 'move',
        checkCrossOrigin: false,
        crop: () => {
            imageEditor.isClean = imageEditorIsClean();
        },
        zoom: () => {
            imageEditor.isClean = imageEditorIsClean();
        },
    });
    imageEditor.initialData = imageEditor.cropper.getData();
};

const loading = ref(false);

const save = () => {
    loading.value = true;
    axios.post(`/api/files/${props.file.id}`, {
        _method: 'PUT',
        crop: imageEditor.cropper.getData(),
    }).then((response) => {
        Object.assign(props.file, response.data.data);
        close();
    }).finally(() => {
        loading.value = false;
    });
};

watch(open, (open) => {
    if (open) {
        nextTick(() => {
            initEditor();
        });
    }
}, { immediate: true });
</script>
<template>
    <Base :open="open" @update:open="close" no-backdrop-blur>
        <Header>
            <DialogClose as="button" type="button">
                <IconCancelX class="size-6" />
            </DialogClose>
            <ModalTitle class="ml-4" show>
                <h1 class="text-lg leading-none font-semibold">Edit Media</h1>
            </ModalTitle>
            <AppButton :loading="loading" type="button" class="ml-auto" rounded-full color="primary" aria-label="Save" compact :disabled="imageEditor.isClean || loading" @click="save">
                <IconCheckBold class="size-6" />
            </AppButton>
        </Header>
        <Body class="bg-gray flex justify-center items-center">
            <div>
                <img draggable="false" :src="file.url" class="w-full max-w-full block h-auto object-cover" ref="image" />
            </div>
        </Body>
        <div class="px-4 py-4 border-t border-white-foreground/10 shrink-0 h-[60px] flex items-center">
            <button
                class="rounded-full p-2 disabled:opacity-50 disabled:cursor-not-allowed"
                type="button"
                @click="imageEditor.reset"
                aria-label="Reset"
                :disabled="imageEditor.isClean"
            >
                <IconClear class="size-7" />
            </button>
            <button
                class="ml-4 rounded-full p-2"
                :class="{ 'bg-primary/20 text-primary': imageEditor.action === 'crop' || imageEditor.cropActive }"
                type="button"
                @click="imageEditor.crop"
                aria-label="Crop"
            >
                <IconCrop class="size-7" />
            </button>
            <!-- <button
                class="ml-4 rounded-full p-2"
                :class="{ 'bg-primary/20 text-primary': imageEditor.action === 'rotate' }"
                type="button"
                @click="imageEditor.rotate"
                aria-label="Rotate 90°"
            >
                <IconRotateClockwise class="size-7" />
            </button> -->
            <button
                class="ml-4 rounded-full p-2"
                :class="{ 'bg-primary/20 text-primary': imageEditor.action === 'zoomOut' }"
                type="button"
                @click="imageEditor.zoomOut"
                aria-label="Zoom Out"
            >
                <IconZoomOut class="size-7" />
            </button>
            <button
                class="ml-4 rounded-full p-2"
                :class="{ 'bg-primary/20 text-primary': imageEditor.action === 'zoomIn' }"
                type="button"
                @click="imageEditor.zoomIn"
                aria-label="Zoom In"
            >
                <IconZoomIn class="size-7" />
            </button>
        </div>
    </Base>
</template>
