<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import axios from '@/axios';

import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import ChannelSelector from '@/views/posts/create/_helpers/ChannelSelector.vue';
import PostForm from '@/views/posts/create/_helpers/PostForm.vue';

const route = useRoute();
const wait = ref(true);
const channel = ref(null);
const step = ref(null);

const channelSelected = (selectedChannel) => {
    channel.value = selectedChannel;
    step.value = 'create-post';
}

const changeChannel = () => {
    step.value = 'select-channel';
}

onBeforeMount(() => {
    if (route.query.channel) {
        axios.get(`/api/channels/${route.query.channel}`).then((response) => {
            channel.value = response.data.data;
            step.value = 'create-post';
        });
    } else {
        step.value = 'select-channel';
    }
});
</script>

<template>
    <Content>
        <Header with-back-button back-button-x>
            <HeaderTitle>
                Create Post
            </HeaderTitle>
        </Header>

        <ChannelSelector v-if="step === 'select-channel'" @channelSelected="channelSelected" :channel-id="channel?.id" />
        <PostForm v-else-if="step === 'create-post'" :channel="channel" @changeChannel="changeChannel" />
    </Content>
</template>
