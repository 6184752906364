<script setup>
import { useStore } from '@/store';

import ContentImages from '@/components/ContentImages.vue';
import LineClamp from '@/components/LineClamp.vue';
import PostContent from '@/components/Post/PostContent.vue';
import PostFooter from '@/components/Post/PostFooter.vue';
import PostHeader from '@/components/Post/PostHeader.vue';
import PostLink from '@/components/Link/PostLink.vue';

const store = useStore();

const props = defineProps({
    post: {
        type: Object,
        required: true,
    },
    as: {
        type: String,
        default: 'li',
    },
    trackingSource: {
        type: String,
        required: false,
    },
    enableTracking: {
        type: Boolean,
        default: false,
    },
});

const getTrackingData = () => {
    if (!props.enableTracking) {
        return null;
    }

    if (props.post.deleted ) {
        return null;
    }

    if (store.state.user && store.state.user.id === props.post.user?.id) {
        return null;
    }

    return {
        source: props.trackingSource,
        item_id: props.post.id,
        item_type: 'post',
    };
};
</script>

<template>
    <component :is="as">
        <div v-track-event:view="getTrackingData">
            <template v-if="post.deleted">
                <div class="bg-white text-white-foreground p-4 flex flex-col md:rounded-md border border-white-foreground/10">
                    <p class="italic">This content has been deleted</p>
                </div>
            </template>
            <template v-else>
                <article class="bg-white text-white-foreground p-4 flex flex-col md:rounded-md border border-white-foreground/10">
                    <PostHeader :post="post" />

                    <PostLink class="block" :post="post">

                        <LineClamp :lines="6" class="mt-2">
                            <PostContent :post="post" />
                        </LineClamp>
                    </PostLink>

                    <ContentImages v-if="post.files" class="mt-2" :images="post.files" />
                    <PostFooter class="mt-2" :post="post" />
                </article>
            </template>
        </div>
    </component>
</template>
